import { computePosition, flip, shift, offset } from '@floating-ui/vue';
import { nextTick } from 'vue';
import { useEventListener } from '@vueuse/core';
let c = 0;
const activeTooltipChecks = new Map();
const debounceDisappearTimeouts = new Map();
const appear = (el, opt) => {
    if (!el.getAttribute('ui-title') && !el.getAttribute('title'))
        return;
    opt = opt || {};
    let uId = el.dataset?.uittId;
    if (!uId) {
        let ttId = `tt-${c++}`;
        el.dataset.uittId = ttId;
    }
    let ttContainer = document.getElementById(el.dataset.uittId);
    if (!ttContainer) {
        ttContainer = document.createElement('div');
        ttContainer.id = el.dataset.uittId;
        ttContainer.classList.add('text-sm', 'max-w-[320px]', 'absolute', 'bg-neutral-950/90', 'text-white', 'p-2', 'rounded-md', 'transition-opacity', 'z-[100000]');
        ttContainer.style.opacity = '0';
        ttContainer.style.display = 'none';
        ttContainer.setAttribute('role', 'tooltip');
        document.body.appendChild(ttContainer);
        ttContainer.addEventListener('transitionend', () => {
            if (ttContainer.style.opacity === '0') {
                ttContainer.remove();
            }
        });
    }
    const update = () => {
        ttContainer.innerHTML = el.getAttribute('ui-title') || el.getAttribute('title');
        computePosition(el, ttContainer, {
            placement: opt.placement || 'top',
            middleware: [offset(4), flip(), shift({ padding: 4 })]
        }).then(({ x, y }) => {
            Object.assign(ttContainer.style, {
                left: `${x}px`,
                top: `${y}px`
            });
        });
    };
    nextTick(() => {
        ttContainer.style.display = 'block';
        requestAnimationFrame(() => {
            ttContainer.style.opacity = '1';
        });
        update();
        // Fica monitorando se o mouse está sobre o elemento ou sobre o tooltip
        if (activeTooltipChecks.has(el))
            cancelAnimationFrame(activeTooltipChecks.get(el));
        const monitorMouseOver = () => {
            const isMouseOver = el.matches(':hover') || ttContainer.matches(':hover');
            if (!isMouseOver) {
                disappear(el);
            }
            else {
                activeTooltipChecks.set(el, requestAnimationFrame(monitorMouseOver));
            }
        };
        activeTooltipChecks.set(el, requestAnimationFrame(monitorMouseOver));
    });
};
const disappear = (el) => {
    if (el.dataset.uittId === undefined)
        return;
    const ttContainer = document.getElementById(el.dataset.uittId);
    if (!ttContainer)
        return;
    if (debounceDisappearTimeouts.has(el)) {
        clearTimeout(debounceDisappearTimeouts.get(el));
    }
    const timeout = setTimeout(() => {
        ttContainer.style.opacity = '0';
        if (activeTooltipChecks.has(el)) {
            cancelAnimationFrame(activeTooltipChecks.get(el));
            activeTooltipChecks.delete(el);
        }
        debounceDisappearTimeouts.delete(el);
    }, 20);
    debounceDisappearTimeouts.set(el, timeout);
};
const onMouseEnter = (el, binding) => {
    appear(el, binding);
};
const onMouseLeave = (el) => {
    disappear(el);
};
export default {
    mounted(el, binding) {
        if (binding.value === false)
            return;
        useEventListener(el, 'mouseenter', () => {
            if (el.title) {
                el.setAttribute('ui-title', el.title);
                el.removeAttribute('title');
            }
            onMouseEnter(el, binding.value);
        });
        useEventListener(el, 'mouseleave', () => {
            onMouseLeave(el);
        });
    },
    unmounted(el) {
        const ttContainer = document.getElementById(el.dataset.uittId);
        if (ttContainer)
            ttContainer.remove();
        if (activeTooltipChecks.has(el)) {
            cancelAnimationFrame(activeTooltipChecks.get(el));
            activeTooltipChecks.delete(el);
        }
        if (debounceDisappearTimeouts.has(el)) {
            clearTimeout(debounceDisappearTimeouts.get(el));
            debounceDisappearTimeouts.delete(el);
        }
    }
};
